<script setup lang="ts">
defineOptions({
  inheritAttrs: false
});

const {
  loading = false,
  direction = "down",
  elementsLength
} = defineProps<{
  loading?: boolean | Ref<boolean> | undefined;
  direction?: "up" | "down";
  elementsLength: number;
}>();

const emit = defineEmits<{
  (e: "loadMore"): void;
}>();

const trigger = ref(null);
const isVisible = ref(false);

useIntersectionObserver(trigger, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting && !loading) {
    emit("loadMore");
  }
});

watch(
  () => elementsLength,
  () => {
    if (isVisible.value) {
      emit("loadMore");
    }
  }
);
</script>

<template>
  <slot v-if="direction === 'down'" name="content"></slot>
  <div ref="trigger" class="min-h-4"><slot v-if="loading" name="loading"></slot></div>

  <slot v-if="direction === 'up'" name="content"></slot>
</template>
